<template>
  <div class="h-100 d-flex align-items-center"></div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      cartId: '',
      tokenCart: '',
      errorMagicLink: false,
    }
  },
  async mounted() {
    document.title = 'Finalizar compra | Clover Tienda Online Oficial'
    this.cartId = this.$route.params.cartId
    this.tokenCart = this.$route.params.token
    this.$store.state.loaderClover = true
    await this.axios({
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/carrito/comprar/' + this.cartId + '-' + this.tokenCart,
    })
      .then(async response => {
        if (window.localStorage.getItem('accessToken')) {
          await this.logOut()
        }
        window.localStorage.removeItem('tokenCart')
        window.localStorage.setItem('tokenCart', response.data.tokenCart)
        await this.getProductsCart()
        if (this.$route.query.url_product) {
          this.$router.replace({
            name: 'ProductDetail',
            params: {
              url: this.$route.query.url_product.replace('product-', ''),
            },
          })
        } else {
          this.$router.replace({ name: 'Bag' })
        }
      })
      .catch(() => {
        this.errorMagicLink = true
        this.$router.replace({ name: 'Bag' })
      })
      .finally(() => {
        this.$store.state.loaderClover = false
      })
  },
  methods: {
    ...mapActions('cart', ['getProductsCart']),
    ...mapActions('user', ['logOut']),
  },
}
</script>
